.grid-image-title {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  color: #ff020a;
  margin-bottom: 10px;
}

.grid-image-session{
    margin-top:5%;
}

.img-fluid{
    border-radius: 20px;
}

.grid-image-header{
    font-size:50px;
    line-height:60px;
}

.grid-column-header{
    font-size:30px;
    line-height:60px;
    font-weight: bold;
    
}

.grid-column-sections{
    
    margin-top: 5%;
}

.row-1{
    margin-top: 15%;
}

.grid-colum-img{
border-radius: 20px;
}

.carousel-session{
    background:#fce5cc;
}

.carousel-container{
    background:#fce5cc;
}

.carousel-card{
    background:#fce5cc !important;
    height:500px;
    border-radius: 0px !important;

}

.shadow-card{
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    padding-left: 0.5%;
}


.faq-wrapper{
    padding:0px 25%;
}

.faq-session{
    margin-top: 5%;
}

.vid-session{
    margin-top: 5%;
}


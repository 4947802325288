.manufacturing-carousel-session {
  background: #ccedff;

  .carousel-container {
    background: #ccedff;
  }

  .carousel-card{
    background:#ccedff !important;
    height:500px;
    border-radius: 0px !important;

}
}

.home-menu {
  background-color: rgb(235, 252, 255);
}

.opportunity-card-session {
  margin-top: 5%;
}

.card-header-text {
  font-size: 50px;
}

.video-session-grid-text {
  font-size: 60px;
  font-weight: bold;
  line-height: 60px;
}

.grid-btn {
  background-color: #17224d;
}

.grid-btn:hover {
  background-color: #ff020a;
}

.opportunity-card {
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.card-img {
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.card-body-side {
  height: 250px;
}



.card-title {
  font-size: 10px;
  color: #ff020a;
  font-weight: bold;
  
}

.card-description {
  font-size: 2rem;
  line-height: 35px;
  // height:50px;
}

.card-link {
  font-size: 0.8rem;
}

.banner-session {
  margin-top: 5%;
}

.banner-header {
  font-size: 3rem;
  padding: 0% 5%;
}

.strokes {
  border-left: 1px solid white;
}

.brand-session {
  margin-top: 5%;
}

.brand-logo {
  filter: grayscale(100%);
}


.vid-header{
 padding:0px 50px;
 text-align: center !important;
 font-size:40px ;
}

.vid-body{
  padding:0px 100px;
}

.card-col-for-mobile{
  width:350px !important;
  border-radius: 20px !important;
  border:5px solid white !important;
  padding:0px !important;
}

.opportunity-sm-card{
  margin-top:100px !important;
  margin-bottom: 15% !important;
}

@media only screen and (min-width: 991px) and (max-width: 1400px) {

  .card-description {
    font-size: 2rem;
    line-height: 35px;
    // height:50px;
  }

  .card-body-side {
    padding:50px !important;
  }
  
}

@media only screen and (min-width: 768px) {
  .card-img {
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }

  .card-body-side {
    padding:25px !important;
  }

  .card-description {
    font-size: 25px;
    line-height: 35px;
    font-weight: bold;
    // height:50px;
  }
}

@media only screen and (max-width: 990px){
  .vid-header{
    padding:0px 5px;
    text-align: center !important;
    font-size:30px !important;
  }
  
  .vid-body{
    padding:0px 50px;
  }
}

@media only screen and (max-width: 767px){
  .vid-header{
    padding:0px 0px;
    text-align: center !important;
    font-size:20px !important;
  }
  
  .vid-body{
    padding:0px 0px;
  }

  .banner-header {
    font-size: 2rem;
    padding: 0% 10%;
  }
}

// .col-figure-stats{
//   border-right: 1px solid white;
// }

@media only screen and (max-width: 420px){
  .vid-header{
    padding:0px 0px;
    text-align: center !important;
    font-size:18px !important;
  }
  
  .vid-body{
    padding:0px 0px;
  }

  .banner-header {
    font-size: 30px;
    padding: 0% 0%;
    font-weight: 200 !important;
  }

  .col-figure-stats{
    border-bottom: 1px solid white;
  }

}
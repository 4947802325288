.package-carousel-session {
    background: #ecedf1;
    margin-top:5%;
  
    .carousel-container {
      background: #ecedf1;
    }
  
    .carousel-card{
      background:#ecedf1 !important;
      height:500px;
      border-radius: 0px !important;
  
  }
  }
  
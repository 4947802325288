// @import url(~bootstrap/scss/variables.scss);



// $primary: #9CC771;
// $theme-colors: (
//   "primary":   $primary,
 
// );



.video-session{
    margin-top: 5%;
  }
  
  .video-session-text-side{
    margin-top:8%;
    padding:0% 12%;
  }


  .footer-grid{
    font-size:12px;
  }

  .footer-section{
    background:#f4f4f7;
    margin-top:5%;
  }

  .dropdown-menu-item{
    font-size:13px;
  }